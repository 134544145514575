import image from "./../../images/portugal-text.jpg";

const Content = () => {
  return (
    <section className="relative py-10" dir="rtl">
      <div className="container mx-auto px-10 text-right">
        <img
          className="float-right mb-5 ml-10 w-1/2"
          src={image}
          alt="Portugal img"
        />
        <p>

          <b>
            רכישת נדל"ן בפורטוגל הנה מגמת השקעה נפוצה בקרב אזרחים זרים, ובכללם אלו
            שמתכוונים לקבל מעמד חוקי במדינת האיחוד האירופי. תוכנית "ויזת הזהב"
            יוצרת תנאים עבור אלו שמעוניינים בהסדרת מעמדם.
          </b>
          <br />
          <br />
            מחירי הנדל"ן בפורטוגל נוחים יותר מאשר במרבית מדינות האיחוד האירופי. כך עלות למטר מרובע במדינה זו הנה 1960–2940 יורו, לאומת 4160–5820 יורו בצרפת, 1980–3080 יורו בספרד או 2170–3480 באיטליה. ברשימת האזורים בהם נהנה הנדל"ן מביקוש מועדף כלולים ליסבון, פורטו, אַלְגָארְבֶה ומדיירה. כל זאת על רקע עלייה מסחררת של מחירי הדיור (ב5 שנים האחרונות הם עלו בשיעור העולה על 35% וממשיכים לעלות ב5–7 אחוזים מדי שנה).
          <br />
          <br /> עלותו ממוצעת של מ"ר מגורים בפורטוגל נכון לאמצע השנה השוטפת הנה
          בסביבות 2000–3000 יורו. הסכום יכול להשתנות באופן משמעותי בהתאם לאזור
          וסוג הפרויקט.
          <br />
          <br /> כך למשל בליסבון עלות ממוצעת של מטר מרובע במרכז הבירה הנה 5800
          יורו ומחוץ לעיר – בסביבות 3100 יורו. בסנטארם, במרחק של כ80 ק"מ מעיר
          הבירה, מחירי הנדל"ן נמוכים בהרבה: עלות הדיור במרכז העיר הנה 1100 יורו
          למ"ר ובפריפריה – החל מ650 יורו לאותה יחידת שטח. מחיר הבתים משתנה גם
          בהתאם למיקום, שטח ומצב.
          <br />
          <br /> ברוב המקרים אין הבדל במחירי הנדל"ן עבור המקומיים ותושבים זרים.
          לרוכשים זרים מוצעות הטבות מס הכנסה לתקופה של עשר שנים וכן שיעור מס
          קבוע של 20% ממחיר הנכס.
        </p>
        <h1 className="py-10 text-2xl font-bold">
          מגמות ודינמיקת עליית המחירים
        </h1>
        <p>
          במהלך 5-7 שנים האחרונות נצפית בפורטוגל עלייה משמעותית של מחירי הנדל"ן.
          הצמיחה מובהקת ביותר בבירת המדינה, ליסבון, פורטו- העיר השנייה בגודלה
          בפורטוגל וכן באזורים תיירותיים. עליית המחירים הנה השלכה של גורמים
          כלכליים, גידול בהיקף התיירות, ואטרקטיביות המדינה עבור משקיעים זרים,
          המקבלים הזדמנות להצטרף לתוכנית "ויזת הזהב" בעקבות רכישת נדל"ן. ניתוח
          שוק מראה כי ממוצע מחירי הדיור בבירה בין השנים 2015 – 2023 עלה מ1330
          יורו ל3800 יורו למ"ר. בשנת 2022 מגמת עליית מחירי הדיור התמתנה מעט
          ומציגה טווח שינויים של 6%. מגמה דומה נצפית גם מחוץ לליסבון, כאשר בין
          השנים 2022 -2023 זוהתה דינמיקה של עליית מחירי נדל"ן של 10 עד 25
          אחוזים.
          <br />
          <br /> ההשוואה בין מחירי הנדל"ן בפורטוגל ומדינות אחרות של האיחוד
          האירופי אינה חד משמעית. כך למשל הדירה במרכז ליסבון זולה יותר מדירה
          באזור דומה של פריס, שם מגיעים מחירי הדיור ל12300 יורו למ"ר. באותה עת,
          עלות הדיור במרכז סופיה, בירת בולגריה, נמוכה בהרבה-כ2500 יורו למ"ר
          בממוצע.
        </p>
        <h1 className="py-10 text-2xl font-bold">הכנסות מהשכרת הנדל"ן</h1>
        <p>
          בפורטוגל, בדומה למדינות אחרות באירופה, הרווח הממוצע מהשכרת נכס תלוי
          במספר גורמים, בהם מיקום, סוג וגודל הנכס ומצב השוק. בשנת 2023 בעלי
          הנדל"ן המקומי זוכה להכנסה חודשית בסך 2 עד 5,5% מעלות הנכס. במהלך השנים
          האחרונות נראית עלייה במחירי השכרת הדיור בפורטוגל, במיוחד בערי תיירות
          וערים מבוקשות כגון ליסבון ופורטו. הביקוש להשכרת חדרים הנו גבוה ויציב
          באופן מסורתי. בשנת 2023 רמת המחירים עבור שרות זה עלתה ב14-35אחוזים בכל
          רחבי המדינה. עלות השכרת חדר בליסבון היא כ600 יורו לחודש, בסטובל ובראגה
          - בסביבות 380 ו350 יורו בהתאמה. יחד עם זאת ההיצע בשנת 2023 גדל
          משמעותית וכמות המודעות עלתה ב50% כמעט.
          <br />
          <br /> עליות חדות במחירי הדיור גרמו לממשלת פורטוגל לנקוט בצעדים לצורך
          בקרת מחירי הדירות בטווח הקצר, זאת כדי להבטיח את זמינות הדיור. ההערכה
          היא שבשנת 2024 בהנחה כי לא תהיינה הגבלות ממשלתיות, משכירי הדירות יוכלו
          להגדיל את רווחיהם ב6,9%.
        </p>
        <h1 className="py-10 text-2xl font-bold">
          פיזור גאוגרפי של רכישת נדל"ן
        </h1>
        <p>
          פורטוגל מתחלקת ליחידות מנהליות (מחוזות, עיריות וקהילות), כאשר לכל אחת
          מאפיינים ייחודיים הדורשים התייחסות מצד רוכשי נדל"ן פוטנציאליים.
          הבולטים מבין הישובים בהם מרבים תושבים זרים לרכוש דיור הנם:
        </p>
        <ul className="list-disc space-y-5 pt-5 pr-5" dir="rtl">
          <li>
            ליסבון וסביבתה. בירת פורטוגל מציעה מגוון נכסים, לרבות נדל"ן שניוני,
            דירות מגורים מודרניות ובתים על גדת הנהר טחו. האזור בעל מורשת תרבותית
            עשירה, תשתית תיירותית מפותחת, רמת חיים גבוהה, שפע של בתי קפה
            ומסעדות. נמל סחורות אטלנטי, הגדול באירופה, מהווה תמריץ נוסף להתפתחות
            העיר.
          </li>
          <li>
            פורטו והמחוז הצפוני. העיר השנייה בגודלה בפורטוגל שמהווה מרכז כלכלי,
            מדעי, תרבותי ועסקי חשוב במדינה. פורטו מציעה תנאים נוחים להתפתחות ענף
            הIT ומעודדת חברות גדולות, בהן VivaLab, Rows, Spectris לפתוח בה
            נציגויות. העיר מזמינה מומחים מקומיים וזרים כאחד לעבוד בה והצעות
            הדיור להשכרה מכוונות לעתים קרובות למגזר זה.
          </li>
          <li>
            אלגארבה. אזור בדרום פורטוגל המוכר בזכות תנאי מזג האוויר הנוחים
            (אקלים מתון), חופי ים נקיים ומגרשי גולף. בעונה החמה מגיעים לאלגארבה
            תיירים ונופשים רבים. מבין הצעות הנדל"ן למכירה ולהשכרה וילות ודירות
            נופש ברצועת החוף הנן המבוקשות ביותר.
          </li>
          <li>
            מדיירה. אי באוקיינוס האטלנטי המהווה אתר נופש ברמה עולמית. בעלי דירות
            נופש ווילות נהנים מהכנסה נאה ביותר. הנדל"ן באזור מהווה השקעה
            אטרקטיבית בשל צמיחה נמרצת של מחירי הדיור וביקוש רב להשכרת הדירות
            בקרב הנופשים.
          </li>
          <li>
            אלנטז'ו. האזור הנו מוקד תיירותי מבוקש. בשל כך רכישת הנדל"ן לצורך
            השכרה הנה עסקה כדאית עבור משקיעים זרים. הנכסים המבוקשים ביותר בקרב
            המבקרים – אחוזות מרווחות בהן ניתן ליהנות מהתבודדות ושלווה.
          </li>
          <li>
            מרכז ומזרח פורטוגל. רמת התפתחות כלכלית, לרבות תשתיות תיירותיות נמוכה
            משמעותית מזו בחוף המערבי של המדינה. היישובית במרכז ומזרח פורטוגל
            פחות מבוקשים בקרב הזרים, אי לכך המחירים כאן נוחים יותר. רוכשים
            פוטנציאליים יכולים לרכוש לעצמם בתים מרווחים וחלקות קרקע במחירים
            אטרקטיביים יותר מאשר ברצועת החוף.
          </li>
        </ul>
        <p className="py-10">
          מחירי הנדל"ן יכולים להשתנות משמעותית בהתאם לסוג הנכס, מיקומו, מצב השוק
          וגורמים אחרים.
        </p>
        <p className="font-bold">
          נציגינו בפורטוגל יסייעו לרוכשים להתמודד עם מאפיינים ייחודיים ודקויות
          תהליך רכישת הדיור. מרגע הנחיתה בנמל התעופה ועד לעזיבתכם, יתלוו אליכם
          בעלי מקצוע מוסמכים מטעמינו בפורטוגל. עם ליווי מקצועי, תמיכה והדרכה
          מושכלת תימנעו מגורמי סיכון וטעויות בבחירת הנכס וסגירת העסקה, שעלולות
          לגרום לבעיות משפטיות ופיננסיות בהמשך.
        </p>
      </div>
    </section>
  );
};

export default Content;

import React from "react";
import Modal from "react-modal";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./Interlayer.css";
import "./InterlayerCountry.css"
import styles from "../quiz/Quiz.module.css";
import portugal from "../../images/countryButton/portugal.png"
import kipr from "../../images/countryButton/kipr.svg"
import thai from "../../images/countryButton/thailand.svg"
import montenegro from "../../images/countryButton/montenegro.svg"
import grecee from "../../images/countryButton/greece.webp"
import spain from "../../images/countryButton/spain.svg"


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    borderRadius: "35px",
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(255,255,255, 0.5)",
  },
};

const countries = [
  { value: "/greece", label: "יוון", flag: grecee },
  { value: "/montenegro", label: "מונטנגרו", flag: montenegro },
  { value: "/spain", label: "ספרד", flag: spain },
  { value: "/portugal", label: "פּוֹרטוּגָל", flag: portugal },
  { value: "/kipr", label: "קַפרִיסִין", flag: kipr },
  { value: "/tailand", label: "תאילנד", flag: thai },
  
];

const InterlayerCountry = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="bg-pastel-grey country-button">
      <div className="container mx-auto w-full py-7">
        <div className="w-full flex justify-center">
          <button
            onClick={openModal}
            className="interlayer__btn animated-button1"
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            בחר מדינה
          </button>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Modal"
          >
            <div className="2xl:w-[1036px] lg:w-[780px] w-screen relative mx-auto flex flex-col items-center bg-white overflow-hidden">
              <button
                onClick={closeModal}
                className="absolute z-10 right-6 top-6"
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  className="text-2xl hover:text-regal-red"
                />
              </button>

              <div className={styles.progressWrap}>
                <h1 className="country-button__modal-title md:text-lg sm:text-md text-sm w-5/6 flex justify-center">
                  בחר מדינה
                </h1>
              </div>
              <div className="country-button__content">
                <div className="country-button__buttons">
                  {
                    countries.map((country:any, i:number) => (
                      <Link to={country.value} key={i} className="country-button__btn btn btn-1 btn-sep icon-info header__logo-tel">
                        <span className="header__logo-tel-txt">{country.label}</span>
                        <img src={country.flag} alt="flag" className="country-button__flag" />
                      </Link>
                    ))
                  }
                </div>
                
              </div>
            </div>
            
          </Modal>
        </div>
      </div>
    </section>
  );
};

export default InterlayerCountry;

import kipr1 from "../../images/kipr/list/1.jpg"
import kipr2 from "../../images/kipr/list/2.jpeg"
import kipr3 from "../../images/kipr/list/3.jpg"
import kipr4 from "../../images/kipr/list/4.jpg"
import kipr5 from "../../images/kipr/list/5.jpg"

import greece1 from "../../images/greece/list/1.jpg"
import greece2 from "../../images/greece/list/2.png"
import greece3 from "../../images/greece/list/3.jpg"
import greece4 from "../../images/greece/list/4.webp"
import greece5 from "../../images/greece/list/5.jpg"

import sevKipr1 from "../../images/sevkipr/list/1.webp"
import sevKipr2 from "../../images/sevkipr/list/2.webp"
import sevKipr3 from "../../images/sevkipr/list/3.jpg"

import portugal1 from "../../images/portugal/list/1.webp"
import portugal2 from "../../images/portugal/list/2.webp"
import portugal3 from "../../images/portugal/list/3.jpg"
import portugal4 from "../../images/portugal/list/4.webp"
import portugal5 from "../../images/portugal/list/5.webp"
import portugal6 from "../../images/portugal/list/6.webp"
import portugal7 from "../../images/portugal/list/7.webp"

import spain1 from "../../images/spain/list/1.webp"
import spain2 from "../../images/spain/list/2.jpg"
import spain3 from "../../images/spain/list/3.webp"
import spain4 from "../../images/spain/list/4.webp"

import montenegro1 from "../../images/montenegro/list/1.jpeg"
import montenegro2 from "../../images/montenegro/list/2.jpg"
import montenegro3 from "../../images/montenegro/list/3.jpg"
import montenegro4 from "../../images/montenegro/list/4.webp"

import thai1 from "../../images/thailand/list/1.webp"
import thai2 from "../../images/thailand/list/2.webp"
import thai3 from "../../images/thailand/list/3.jpg"
import thai4 from "../../images/thailand/list/4.jpg"
import thai5 from "../../images/thailand/list/5.jpg"

let blocksFunc = (code: any) => {
  if (code === "pt") {
    const blocks:any = [
      {
        title: 'הכנת מסמכים ובדיקת כשירות משפטית',
        desc: `עורך הדין שלנו יעזור לכם באיסוף כל מכלול המסמכים, תרגום לשפה הפורטוגזית והוספת אפוסטיל.
        המשקיע יעבור את בדיקת Due Diligence מקדימה, במסגרתה יספק מידע אודות אישיותו, עיסוקיו ומקורות ההכנסה. זה יאפשר הכנה מוקדמת לבדיקה הראשית ויפחית את הסיכוי לסירוב.
        `,
        image: portugal1,
      },
      {
        title: "הגשת המסמכים ובחירת הנכס",
        desc: `ניתן לבחור את הנכס מרחוק, מתוך בסיס הנתונים של נכסים בדוקים. טרם הנסיעה ניתן להכין מראש את רשימת הנכסים המועדפים ולבצע סקירה מקדימה אונליין.
        המשקיע יגיע לפורטוגל כדי לראות את הנכסים. המומחה שלנו ילווה אתכם בכל שלב ויסייע בהגשת המסמכים. במהלך הביקור ניתן ללמוד על עורך החיים, התרבות ומסורת המדינה.
        `,
        image: portugal2,
      },
      {
        title: 'מספר תיק מיסוי וחשבון בנק',
        desc: `לשם ביצוע עסקת הנדל"ן יש לקבל NIF – מס' תיק מיסוי (Número de Identificação Fiscal). עורך הדין יבצע זאת עבור המשקיע בלשכת המיסים האזורית, בהתאם למיקום הנכס.
        יש גם צורך בפתיחת חשבון בנק אליו יזרמו כספי ההשקעה וממנו ישולמו כל האגרות הממשלתיות. רכישת נדל"ן אפשרית רק באמצעות תשלום שלא מערב מזומן.`,
        image: portugal3,
      },
      {
        title: 'חתימה על זיכרון דברים והעברת מקדמה',
        desc: `זיכרון הדברים מאשר את התחייבות המוכר והקונה לבצע עסקה וקובע את המחיר.
        הקונה מעביר מקדמה, כדי לשמור את הזכות על הנכס, לרוב בין 10 ל30% מסך המחיר, בהתאם לתנאי המוכר. במידה והקונה יסרב לחתום על ההסכם הסופי, המקדמה תוותר בידי המוכר. במידה ויסרב המוכר – הקונה יקבל את המקדמה הכפולה.
        `,
        image: portugal4,
      },
      {
        title: 'הכנת העסקה',
        desc: `עורך הדין שלנו בפורטוגל יערוך את הבדיקות הנחוצות, יבדוק את מסמכי הנכס, יוודא שלא קיימים שעבודים או חובות  מיסים ותשלומים שוטפים. במידה ויימצאו חוסרים המוכר מתחייב לסלקם תוך תקופת זמן הקבועה בזיכרון דברים.`,
        image: portugal5,
      },
      {
        title: 'העסקה הסופית',
        desc: `עד שלושה ימים טרם החתימה על ההסכם הסופי יש לשלם אגרה ממשלתית (Imposto de Selo) ומס העברת זכויות הבעלות (Imposto Municipal Sobre as Transaccoes, IMT). זה מתבצע בלשכת המיסים האזורית, בהתאם למיקום הנכס.
        סך האגרה הממשלתית הנו 0,8% מעלות הנכס כפי שמצוין בהסכם המכירה וסך מס הIMT תלוי בעלות וסוג הנכס.
        עבור נכסם מסחריים המס מסתכם ב6,5% ועבור דיור נקבע על פי מדרג עולה.
        הלקוח יגיע למעמד חתימת ההסכם הסופי אודות העסקה בליווי עורך הדין שלנו. החתימה תתרחש בפורטוגל, במשרד נוטריון מורשה. הכספים יוזרמו לחשבון נאמנות ייעודי ומשם יועברו אל הקונה. 
        החשבון יפתח בבנק אשר יתפקד כנאמן. הכספים יירשמו בחשבון וינוכו ממנו בהתאם לצורך כתשלום עבור נכס הנדל"ן. 
        את אישור הרכישה הבנקאי על שם הקונה יש להציג בפני שרות ההגירה כאישור השקעה.
        `,
        image: portugal6,
      },
      {
        title: 'רישום הבעלות על הנכס',
        desc: `אנו מבצעים רישום של הבעלים החדש בשרות המיסים ולשכת המקרקעין (Conservatoria do Registo Predial).  תקבלו תעודת רישום חדשה (Certidão de Teor) וכן את פנקס דיירי הבית. 
        אנו נחדש את כל הסכמי שרותי הדיור. ניתן להשאיר את הספקים השוטפים או לבחור ספקי שרות אחרים.
        
        אין צורך להיבהל מההליכים המפורטים לעיל. אנו לוקחים על עצמנו את כל הפעולות הכרוכות ברכישת נדל"ן בפורטוגל משלב הבחירה ועד לרישום ושרות שלאחר המכירה.
        `,
        image: portugal7,
      }
    ];
    return {mainTitle: 'תהליך מדורג של רכישת נדל"ן בפורטוגל', blocks};
  } else if (code === "ncy") {
    const blocks:any = [
      {
        title: 'בחירת הנכס ותיאום תנאים',
        desc: `בהתאם למטרת הקניה – מגורים, נופש או השכרה, ניתן לבחור נכס בצמוד לחוף או באזור ההרים, בישוב קטן או בעיר גדולה ומפותחת.
        מחירי הנדל"ן בצפון קפריסין מצוינים באופן מסורתי בלירות שטרלינג הבריטיות אך ניתן לנהל את ההתחשבנות גם במטבע אחר: לירה תורכית, יורו או דולר. 
        כאשר נמצא הנכס שתואם את ציפיותיכם מועברת מקדמה בסך 5000 לירות שטרלינג כדי שהנכס לא יוצג למכירה.
        יזמי הבניה בדרך כלל מאפשרים פריסה לתשלומים עד להשלמת הקמת המבנה. לעתים הפריסה היא ללא ריבית, אך לפעמים הריבית קיימת. התשלום הראשון הנו עד 35% ממחיר הדירה. במידה והמתחם המגורים נמצא בשלבי הקמה אחרונים או מוכן לקראת אכלוס תדרשו להעביר 70-80% מהסכום הכולל.
        במהלך כל תקופת ההתקשרות עם המוכר הקונה מיוצג ע' עורך דין מוסמך מטעמנו. הוא בודק את המסמכים, מכין את הסכם המכירה, מבצע את הרישום ברשות לרישום המקרקעין, מנהל את ההתקשרות עם רשויות המס ואת הליכי העברת הסטטוס (זכויות הבעלות) על שמכם. 
        עורך הדין הוא אדם בו תוכלו לתת אמון מלא. הוא מקפיד לפעול בהתאם לחוק ולא יאפשר כל חריגה כי בכך יסכן את רישיון עבודתו.
        `,
        image: sevKipr1,
      },
      {
        title: "חתימה על ההסכם והעברת ",
        desc: `בדרך כלל הקונה משלם למוכר את תמורת הנכס ע' העברת הכספים לחשבון היזם או חשבונו של אדם פרטי אם מדובר בשוק השניוני. 
        לאחר העברת התשלומים בהתאם ללוח הזמנים המתואם בין הצדדים נחתם הסכם המכר. הוא נערך באנגלית ובעברית (בהתאם לבקשה). בסוף ההסכם חותמים המוכר, הקונה או נציגו ו2 עדים .
        לאחר החתימה על ההסכם על הקונה לשלם אגרה ממשלתית בסך 0,5% מהסכום.
        לאחר מכן יש להציג את ההסכם לרשות לרישום מקרקעין לשם הסדרת רישומו (עד השלב הזה יש להעביר 35% מהסכום לפחות). בהתאם לחוק המקומי יש להציג את ההסכם לרישום תוך 21 ימים מיום חתימתו. רישום ההסכם מעניק לקונה סטטוס של בעל נדל"ן.
        `,
        image: sevKipr2,
      },
      {
        title: "קבלת הסטטוס",
        desc:  `
        בקפריסין קיים מסמך, המאשר את זכויות הבעלות המלאות על נכס – תעודת הסטטוס או Title Deed. יש לציין כי קיומו אינו הכרחי. בהתאם להסכם המכר הנכם הבעלים החוקיים של הדירה. תוכלו לעשות בה כל שימוש – להתגורר בה, להשכיר או להעביר כירושה. 
        עם זאת, בהיעדר תעודת הסטטוס, מכירת הנכס תדרוש תיאום מראש עם היזם. זו מגבלה מסוגה, לכן כיום כל הקונים שואפים לקבל את המסמך.
        לשם הסדרת תעודת הסטטוס על תושב זר לקבל רישיון בעלות על נדל"ן ממשרד הפנים של הרפובליקה. התהליך אינו מהיר ונמשך בין 3 ל12 חודשים (התקופה משתנה בהתאם לאזרחות מגיש הבקשה).
        על מנת לקבל את התעודה אודות הבעלות לאחר קבלת הרישיון יש לשלם מע"מ בסך 5% מהסכום, אגרת הסדרת הסטטוס בסך 3% וכן אגרת המרה (מ1000 עד 3000 ליש"ט).
        במקרה של רכישת הדירה בשוק השניוני ייתכן ותעודת הסטטוס כבר קיימת וכל המיסים שולמו. אזי אין כל קושי בהעברת התעודה על שם בעלים אחר.
        `,
        image: sevKipr3,
      },
    ];
    return {mainTitle: 'תהליך רכישת נדל"ן בצפון קפריסין', blocks};
  } else if (code === "cy") {
    const blocks:any = [
      {
        title: 'איך רוכשים דירה בקפריסין היוונית. שלב אחר שלב.',
        desc: `העניין במגורים במדינה זו גדל משנה לשנה. מכירות הנדל"ן בקפריסין הגיעו ל1.7 מיליארד יורו ברבעון הראשון של שנת 2023 על סמך הדו"ח של לשכת המקרקעין והמחקר.
        מחירי הדיור בקפריסין עלו ב10% בממוצע בשנת 2022 וממשיכים לצמוח בשנת 2023.
        מומחים מוסמכים מטעמינו ילוו אתכם בכל שלבי העסקה. אנו נעזור לכם לבחור את הנכס, נוודא את כשירותו המשפטית. במידת הצורך נבקש את חוות דעתו של אדריכל בלתי תלוי.
        `,
        image: kipr1,
      },
      {
        title: 'בחירת הנכס',
        desc: `ניתן לבחור את הנכס באופן אישי אך גם מרחוק. ברשותנו בסיס נתונים בדוק של נדל"ן בשלבי בנייה שונים. אנו מוכנים לארגן שידור וידאו בליווי הסבר ותיאור מפורט.
        חשוב לשמור את הנכס לזכותכם כדי שהמוכר יקפיא את שיווקו. לשם כך חותמים הצדדים על הסכם מקדים והקונה מעביר מקדמה - כ 10% משווי השוק של הנכס. 
        המחיר המדויק נקבע בהדברות עם המוכר. העסקה מתבצעת תחת פיקוחו של עורך דין מטעמנו, הכספים מועברים לחשבון הנאמנות של המוכר. הבנק מבטיח החזר במקרה של קשיים. 
        `,
        image: kipr2,
      },
      {
        title: "מספר תיק מיסוי וחשבון בנק",
        desc: `לשם ביצוע עסקת הנדל"ן יש לקבל NIF – מס' תיק מיסוי (Número de Identificação Fiscal). עורך הדין יבצע זאת עבור המשקיע בלשכת המיסים האזורית, בהתאם למיקום הנכס. יש גם צורך בפתיחת חשבון בנק אליו יזרמו כספי ההשקעה וממנו ישולמו כל האגרות הממשלתיות. רכישת נדל"ן אפשרית רק באמצעות תשלום שלא מערב מזומן.`,
        image: kipr3,
      },
      {
        title: 'בדיקת כשירותו המשפטית של הנכס',
        desc: `לאחר החתימה על הסכם מקדים נבדקת כשירותו המשפטית של הנכס. במסגרת זו ניתן לברר האם קיימות הגבלות כלשהן למכירה, חובות בתשלומים שוטפים. לאחר הבדיקה הצדדים מגיעים להסכמה על תנאי העסקה הסופיים.`,
        image: kipr4,
      },
      {
        title: "חתימה על הסכם המכר",
        desc: `על תושבים זרים בקפריסין לקבל אישור ממשלתי לרכישה. משמעות האישור היא שעברתם בהצלחה את בדיקת הנאותות.
        עורך הדין שלנו, שילווה את עסקת המכירה, יבצע את תשלום האגרה הממשלתית וימסור עותק אחד מהסכם המכר ללשכת המקרקעין. לאחר מכן לא יוכל הבעלים לבצע בנכס כל פעולה, כגון השכרה או תוספות בנייה.
        לאחר החתימה על ההסכם הקונה משלם את כל הסכום כולל מע"מ בניכוי המקדמה. הכספים שוב אינם מועברים ישירות אל המוכר אלא לחשבון ייעודי של עורך הדין המייצג אתכם. 
        לאחר החתימה על הסכם המכר והעברת כל הכספים, מבצעים שרותי ההגירה את בדיקת ה Due Diligence של המשקיע וכל הבגירים המצוינים בבקשה.
        `,
        image: kipr5,
      }
    ];
    return {mainTitle: 'איך רוכשים דירה בקפריסין היוונית. שלב אחר שלב.', blocks};
  } else if (code === "gr") {
    const blocks:any = [
      {
        title: 'בחירת הנכס',
        desc: `ניתן לבחור את הנכס מרחוק, מתוך בסיס הנתונים של נכסים בדוקים. טרם הנסיעה ניתן להכין מראש את רשימת הנכסים המועדפים ולבצע סקירה מקדימה אונליין.
        עליכם להגיע ליוון כדי לראות את הנכסים. המומחה שלנו ילווה אתכם בכל שלב ויסייע בהגשת המסמכים. במהלך הביקור ניתן ללמוד על עורך החיים, התרבות ומסורת המדינה. לאחר בחירת הנכס ביוון יש לשמור אותו לזכותכם. לשם כך יעביר הקונה מקדמה בהתאם לעלות הנכס. סך המקדמה נע בין 2000 ל5000 יורו.
        `,
        image: greece1,
      },
      {
        title: "קבלת מס' משלם מס ביוון ",
        desc: `קבלת מספר משלם מס אישי דרושה לשם ביצוע כל ההתחשבנות בנוגע לעסקה. המספר הזה ילווה אתכם בכל פעולה הכרוכה במיסוי, לרבות מכירה, קניה והשכרה של נדל"ן ואף פתיחת עסק. המספר ניתן בלשכת המיסים המקומית. בדרך כלל עורך הדין מטעמינו המלווה את העסקה שלכם יעסוק בכך. `,
        image: greece2,
      },
      {
        title: "בדיקה משפטית של הנכס",
        desc: `עורך הדין יבדוק את הנכס מבחינת היעדר חובות ושעבודים ויכין את דו"ח בדיקת הבעלות. בנוסף מתבצעת בדיקה של מוכר הנדל"ן והאם אכן יש לו זכויות בעלות רשומות. כל ההכנות לקראת חתימת ההסכם בעת היעדרותכם ביוון  מנהל בשמכם עורך הדין עם ייפוי כוח נוטריוני.`,
        image: greece3,
      },
      {
        title: 'חתימה על הסכם המכירה',
        desc: `לאחר שעורך הדין מאשר את חוקיות כל המסמכים, מה שנותר הוא לחתום על ההסכם. ההסכם נחתם בנוכחות הנוטריון ועורך הדין. המסמך הנו בשפה היוונית. לבקשת הלקוח ההסכם יתורגם לכל שפה עם אישור המתרגם. במקביל (או תוך תקופה מוגדרת מראש, אשר בפועל הנה 7-14 ימים) הקונה מעביר את יתרת המחיר ומקבל את מפתחות הנכס.
        ההסכם עם אישור נוטריוני מהווה את המסמך העיקרי המאשר את זכות הבעלות על הנכס או חלקת קרקע. ההסכם מתאר בפירוט את הנכס הנמכר ועלותו. הנוטריון הנו ערב לביצוע ההסכם היות וביוון הוא נחשב לנציג המדינה.
        `,
        image: greece4,
      },
      {
        title: 'רישום הבעלים החדש',
        desc: `לשם הסדרת מעבר זכויות הבעלות עורך הדין ירשום את ההסכם אצל רשם המקרקעין. במעמד הגשת הסכם המכירה  לצורך רישומו הלשכה תנפיק אישור בהתאם. התעודה עצמה תונפק תוך מספר חודשים, בהתאם לעומס לשכת הרישום.`,
        image: greece5,
      }
    ];
    return {mainTitle: 'שלבי רכישת נדל"ן ביוון', blocks};
  } else if (code === "mtng") {
    const blocks:any = [
      {
        title: 'בחירת הנכס',
        desc: `ניתן לבחור את הנכס באופן אישי אך גם מרחוק. ברשותנו בסיס נתונים בדוק של נדל"ן בשלבי בנייה שונים. אנו מוכנים לארגן שידור וידאו בליווי הסבר ותיאור מפורט.
        חשוב לשמור את הנכס לזכותכם כדי שהמוכר יקפיא את שיווקו. לשם כך חותמים הצדדים על הסכם מקדים והקונה מעביר מקדמה - כ 10% משווי השוק של הנכס. 
        המחיר המדויק נקבע בהדברות עם המוכר. העסקה מתבצעת תחת פיקוחו של עורך דין מטעמנו, שיבדוק כי בנוגע לנכס לא קיימים חובות כלשהם וכן יסקור את המסמכים כדי לוודא כי אין בהם טעויות או אי דיוקים.
        `,
        image: montenegro1,
      },
      {
        title: "חתימה על הסכם המכר",
        desc: `ישנן שתי אפשרויות – חתימה על הסכם מקדים (predugovor) או הסכם עיקרי (ugovor). ההסכם המקדים נחתם בדרך כלל במסגרת רכישת נכס שבנייתו טרם הושלמה או בהתאם לדרישת מי מהצדדים. בשני המקרים ההסכם נערך ומאושר ע' הנוטריון.
        שימו לב! במידה והקונה מוותר על העסקה לאחר חתימת ההסכם, המקדמה לא תוחזר לידיו. במידה וביטול העסקה הוא באחריות המוכר, הוא מתחייב להחזיר מקדמה כפולה.
        `,
        image: montenegro2,
      },
      {
        title: "תשלום כלל הוצאות העסקה (מחיר הנכס, מיסים ואגרות)",
        desc: `בדרך כלל תשלום תמורת הנכס מתבצע ללא שימוש במזומן, מחשבון שנפתח בבנק של כל מדינה לחשבון המוכר או הנוטריון. 
        עמלת הנוטריון היא עד 0,01% מעלות הנכס.
        בנוסף, לאחר החתימה על הסכם המכר על הקונה לשלם את מס העברת נדל"ן - 3% משווי השוק של הנכס. המס לא חל על רכישת נדל"ן חדש מישות משפטית המשלמת מע"מ.
        שימו לב! החל מ1 בינואר 2024 מיסי רכישת נדלן במונטנגרו הם:
        עבור נכסים שעלותם עד 150,000 יורו שער המס נותר ללא שינוי והנו 3%.
        עבור נכסים שעלותם מ150,000 ועד 500,000 יורו המס מורכב משני חלקים. מס קבוע בסך 4500 יורו בתוספת  5% מהפרש מחיר הנדל"ן.
        רוכשי נכסי עלית יאלצו לשלם סך קבוע של 22,000 יורו בתוספת  6% מהפרש מחיר הנדל"ן.
        `,
        image: montenegro3,
      },
      {
        title: 'רישום זכויות הבעלות',
        desc: `תוך 30 ימי עבודה מחתימת ההסכם יש לרשום את זכויות הבעלות שלכם במנהלת מקרקעין של מדינת מונטנגרו. אגרת הרישום הנה 0,5% מעלותו הרשומה של הנכס. זהו זה! אתם בעלי נדל"ן במונטנגרו!`,
        image: montenegro4,
      },
    ];
    return {mainTitle: 'תהליך מדורג של רכישת נדל"ן במונטנגרו.', blocks};
  } else if (code === "th") {
    const blocks:any = [
      {
        title: 'בחירת הנכס',
        desc: `ניתן לבחור את הנכס מרחוק, מתוך בסיס הנתונים של נכסים בדוקים. טרם הנסיעה ניתן להכין מראש את רשימת הנכסים המועדפים ולבצע סקירה מקדימה אונליין. עליכם להגיע לתאילנד כדי לראות את הנכסים שבחרתם. המומחה שלנו ילווה אתכם בכל שלב ויסייע בהגשת המסמכים. במהלך הביקור ניתן ללמוד על עורך החיים, התרבות ומסורת המדינה. לאחר בחירת הנכס בתאילנד יש לשמור אותו לזכותכם. לשם כך יעביר הקונה מקדמה בהתאם לעלות הנכס. סך המקדמה משתנה, אך לרוב מדובר ב10% מסך עלות הנכס.`,
        image: thai1,
      },
      {
        title: "חתימה על הסכם המכר",
        desc: `ישנן שתי אפשרויות – חתימה על הסכם מקדים (predugovor) או הסכם עיקרי (ugovor). ההסכם המקדים נחתם בדרך כלל במסגרת רכישת נכס שבנייתו טרם הושלמה או בהתאם לדרישת מי מהצדדים. בשני המקרים ההסכם נערך ומאושר ע' הנוטריון.
        שימו לב! במידה והקונה מוותר על העסקה לאחר חתימת ההסכם, המקדמה לא תוחזר לידיו. במידה וביטול העסקה הוא באחריות המוכר, הוא מתחייב להחזיר מקדמה כפולה.
        `,
        image: thai2,
      },
      {
        title: "תשלום כלל הוצאות העסקה (מחיר הנכס, מיסים ואגרות)",
        desc: `בדרך כלל תשלום תמורת הנכס מתבצע ללא שימוש במזומן, מחשבון שנפתח בבנק של כל מדינה לחשבון המוכר או הנוטריון. 
        עמלת הנוטריון היא עד 0,01% מעלות הנכס.
        בנוסף, לאחר החתימה על הסכם המכר על הקונה לשלם את מס העברת נדל"ן - 3% משווי השוק של הנכס. המס לא חל על רכישת נדל"ן חדש מישות משפטית המשלמת מע"מ.
        שימו לב! החל מ1 בינואר 2024 מיסי רכישת נדלן במונטנגרו הם:
        עבור נכסים שעלותם עד 150,000 יורו שער המס נותר ללא שינוי והנו 3%.
        עבור נכסים שעלותם מ150,000 ועד 500,000 יורו המס מורכב משני חלקים. מס קבוע בסך 4500 יורו בתוספת  5% מהפרש מחיר הנדל"ן.
        רוכשי נכסי עלית יאלצו לשלם סך קבוע של 22,000 יורו בתוספת  6% מהפרש מחיר הנדל"ן.
        `,
        image: thai3,
      },
      {
        title: 'רישום זכויות הבעלות',
        desc: `תוך 30 ימי עבודה מחתימת ההסכם יש לרשום את זכויות הבעלות שלכם במנהלת מקרקעין של מדינת מונטנגרו. אגרת הרישום הנה 0,5% מעלותו הרשומה של הנכס. זהו זה! אתם בעלי נדל"ן במונטנגרו!`,
        image: thai4,
      },
      {
        title: 'רישום זכויות הבעלות',
        desc: `תוך 30 ימי עבודה מחתימת ההסכם יש לרשום את זכויות הבעלות שלכם במנהלת מקרקעין של מדינת מונטנגרו. אגרת הרישום הנה 0,5% מעלותו הרשומה של הנכס. זהו זה! אתם בעלי נדל"ן במונטנגרו!`,
        image: thai5,
      },
    ];
    return {mainTitle: 'שלבי רכישת נדל"ן בתאילנד', blocks};
  } else if (code === "es") {
    const blocks:any = [
      {
        title: "בחירה ושמירה על זכויות בנכס המועדף עליך",
        desc: `השלב הראשון הוא תמיד בחירה ושמירת זכויות על הנכס המועדף עליכם. הלקוח יכול להתחיל את תהליך הבחירה בספרד. עם הגעתכם לנמל התעופה יפגוש אתכם נציג החברה שיציע שרותי הסעה ושיכון בבית מלון. 

        סיור היכרות בעיר, הכרת תשתיות עירוניות ואזורי התיירות בסביבתם, ביקור באטרקציות. בחינת נכסי הנדל"ן בליווי היועץ שלנו, דובר אנגלית או עברית. 
        במידה ובחרתם נכס בו אתם מעוניינים מתבצע הליך שמירת הזכויות.
        
        הסכם שימור הזכויות. בהסכם מצוין מחיר הנכס ונקבעים המועדים לחתימה על שטר קנייה. במעמד זה הקונה לרוב מעביר מקדמה בסך 1-1,5% מעלות נכס המגורים. בדרך כלל €3000 זהו סכום מספק עבור דירה. היות וההסכם מעיד על כוונת החותמים לסגור עסקה, לאחר חתימתו הנכס מוסר מרשימת הנכסים המוצעים למכירה ומפסיקים להציגו ללקוחות אחרים. במידה והקונה יחליט לוותר על הרכישה, המקדמה תישאר בידי המוכר.
        `,
        image: spain1,
      },
      {
        title: "הכנות לקראת העסקה",
        desc: `קבלת מספר זיהוי של נתין זר (NIE).
        פתיחת חשבון בבנק ספרדי.
        בדיקת הסטטוס המשפטי של הנכס. 
        חתימה על הסכם מקדים (זיכרון דברים). במעמד זה מעביר הקונה  10% מהסכום לתשלום. אם ישנה הקונה את דעתו ויוותר על רכישת הנכס, המקדמה תישאר בידי המוכר. במידה והמוכר יבטל את העסקה הוא ישלם לקונה מקדמה כפולה. 
        קבלת משכנתא (במידת הצורך).
        `,
        image: spain2,
      },
      {
        title: 'סגירת העסקה',
        desc: `חתימה על הסכם המכירה. כשכל המסמכים מוכנים מגיע השלב של סגירת העסקה: יש להעביר את יתרת הסכום לחשבון המוכר ולחתום על הסכם המכירה בנוכחות הנוטריון. הקונה חותם על המסמכים מול בעל הנכס או חברת היזמות. 
        תשלום המיסים ואגרות רישום.
        קבלת תעודה אודות זכות הבעלות. רישום הבעלות על נדל"ן בספרד מתבצע אצל הרשם הממשלתי, בפניו יש להציג את ההסכם המקורי ואישורי תשלום המיסים.
        `,
        image: spain3,
      },
      {
        title: 'הבעלות על נדל"ן ',
        desc: `חתימה או חידוש הסכמי שרותי הדיור. 
        תשלום עבור שירותים שוטפים
        השכרת הנכס (במידת הצורך)
        קבלת מולטי-ויזה למשך שנה או אישור תושב (במידת הצורך).
        
        רכישת דיור בחו"ל  - תהליך עתיר אחריות הדורש גישה מושכלת, בייחוד אם מטרתכם לא רק לקנות דירת מגורים בספרד לשימוש אישי, אלא גם לקצור רווחים בהמשך. 
        
        אין צורך להיבהל מההליכים המפורטים לעיל. אנו לוקחים על עצמנו את כל הפעולות הכרוכות ברכישת נדל"ן בספרד משלב הבחירה ועד לרישום ושרות שלאחר המכירה, כולל:
        הכנה וחידוש הסכמים עם ספקי שרותי דיור ורישום בכתובת החדשה. 
        פתיחת תיק מיסוי, הסדרת ביטוחים.
        הסדרת מסמכים הכרוכים ברכישת הנכס, לרבות מסמכי אישור תושב, ירושה, העברה ללא תמורה. 
        מתן שירותים נלווים, כולל סיוע בחיפוש אחר צוותי שיפוצים, עיצוב, רכישת ריהוט.
        ארגון תהליך ההשכרה (הסכמים, המלצות, סיוע בחיפוש דיירים).
        `,
        image: spain4,
      },
    ];
    return {mainTitle: 'תהליך רכישת הדירה בספרד מתרחש ב4 שלבים, כאשר לכל שלב מספר תת-שלבים.', blocks};
  }
}

export default blocksFunc;
import image from "./../../images/kipr-text.webp";

const Content = () => {
  return (
    <section className="relative py-10" dir="rtl">
      <div className="container mx-auto px-10 text-right">
        <img
          className="float-right mb-5 ml-10 w-1/2"
          src={image}
          alt="Portugal img"
        />
        <p>
          <b> 
            קפריסין נמנה על היעדים המבוקשים והאטרקטיביים ביותר להשקעות, נופש
            ומגורים.
            <br /> האי מרהיב ביופיו, בעל היסטוריה מרתקת ותשתית תיירותית מפותחת.
          </b>
        </p>
        <p className="pt-10">קהשקעה בנדל"ן בקפריסין-יתרונות</p>
        <p className="font-bold py-3">
          תושבות קבע ואזרחות בעקבות רכישת נכס בקפריסין
        </p>
        <p>
          האפשרות לקבלת מעמד תושב קבע או אזרחות הנו שיקול חשוב בבחירת המדינה
          להשקעה. בקפריסין קיימת תוכנית המאפשרת לאזרחים זרים לקבל מעמד של תושב
          קבע בעקבות השקעה בת 300 אלף יורו. נכון להיום מדובר בתוכנית היחידה
          לקבלת מעמד תושב קבע באיחוד האירופי שזמינה לאזרחי רוסיה. המע"מ ברכישת
          נדל"ן בקפריסין הנו 19%, אך אם בבעלות הרוכש אין דיור אחר בקפריסין, פרט
          לנכס הנרכש, המע"מ ייגבה על פי שער נמוך של 5%. שלב הבניה אינו חשוב
          מבחינת הסדרת מעמד תושב קבע. ניתן לרכוש נדל"ן בשלב עבודות עפר או בשלב
          מתקדם יותר לקראת האכלוס. מהבדיל מתוכניות דומות במדינות אירופה אחרות לא
          ניתן להשכיר את הנכס עבורו קבל המשקיע את מעמד תושב הקבע.
        </p>
        <p className="font-bold pt-10">הכנסה פוטנציאלית גבוהה משכרות.</p>
        <p>
          השקעה בנדל"ן בקפריסין מניבה הכנסה חודשית הן מהשכרת הנכס והן מעליית
          מחירו. רווחיות הפרויקטים בקפריסין נעה בין 3,8 ל5 אחוזים לשנה, בהתאם
          לסוג הנכס ומיקומו. הביקוש לנכסים מסחריים ודיור בעיר הנו גבוהה. תנאי
          האקלים ומזג האוויר הייחודיים באי וכ300 ימי שמש הופכים את קפריסין לאתר
          נופש מושלם בעל תקופה תיירותית הארוכה באירופה. המשקיעים משכירים את
          הנכסים לתיירים ממאי ועד אוקטובר.
        </p>
        <p className="font-bold pt-10">הגנה מפני אינפלציה, רווחיות</p>
        <p>
          הנדל"ן הנו נכס השומר על מחירו לטווח ארוך וכן מניב רווחים בצורת דמי
          שכירות או הוון. האינפלציה בקפריסין הגיעה לשיא של 8,1% בשנת 2022 על רקע
          העלייה העולמית החדה במחירי חומרי הגלם. השנה האינפלציה צפויה לרדת
          ל3,8%. מחירי הנדל"ן בקפריסין עולים בקביעות מה שמגדיל את הוונו. זהו נכס
          כדאי המביא עמו את כל יתרונותיו של מעמד תושב הקבע. עליית מחירי הנדל"ן
          בקפריסין גוברת על האינפלציה. על פי הבנק המרכזי של קפריסין קיימת צמיחה
          רבעונית ושנתית של מחירי הנדל"ן ברבעון האחרון של שנת 2022. עלות הבתים
          הפרטיים צמחה ב10,4% בשנה ומחירי הדירות עלו ב9,3% בלימסול, ב9,4% בלרנקה
          וב8,6% בפאפוס. יש לציין כי בשל עלויות אחזקה נמוכות, הרווח הנקי מנכס
          בקפריסין הנו לעתים קרובות גבוהה בהשוואה למדינות אחרות של האיחוד
          האירופי.
        </p>
        <p className="font-bold pt-10">אזורי ההשקעה העיקריים בקפריסין</p>
        <p>
          דירות הנופש והווילות המפוארות המבוקשות ביותר ממוקמות בעיקר בשני
          האזורים היוקרתיים וסביבתם הקרובה: לימסול ופאפוס. לימסול נחשבת לעיר
          הטובה ביותר בעלת קהילה נרחבת של דוברי רוסית בעוד שמשקיעים מהאיחוד
          האירופי מעדיפים את פאפוס.
        </p>
        <p className="font-bold pt-10">לימסול</p>
        <p>
          לימסול-עיר נופש בעלת תשתית מפותחת ואוכלוסייה גדולה. בין עשרה לחמישה
          עשר אחוז מתושבי העיר הנם מהגרים מרוסיה ומדינות חבר העמים. המחיר הממוצע
          לדירה בעלת שני חדרי שינה בשכונות היוקרה של לימסול נע בין 250 ל400 אלף
          יורו. מחירן של וילות עם בריכה פרטית ונוף אל הים יכול להגיע למיליון
          יורו ויותר.
        </p>
        <p className="font-bold pt-10">פאפוס</p>
        <p>
          פאפוס הנה בין הערים העתיקות והיפות ביותר בקפריסין. זו העיר השנייה
          מבחינת ביקוש בקרב מהגרים דוברי הרוסית. מחירי הדירות בפאפוס – החל מ80
          אלף יורו ובתים פרטיים – החל מ150 אלף יורו. מחיר ממוצע לדירה בעלת שני
          חדרי שינה בשכונה יוקרתית בפאפוס הנו בין 150 ל300 אלף יורו. מחירן של
          וילות עם בריכה פרטית ונוף אל הים יכול להגיע ל400 אלף יורו ויותר.
        </p>
        <p className="font-bold pt-10">לרנקה</p>
        <p>
          העיר השלישית בגודלה בקפריסין-השילוב המושלם בין מסורת לעכשוויות. מורשתה
          התרבותית העשירה לצד אווירה קוסמופוליטית משגשגת הופכת את העיר למקום
          מזמין למגורי קבע. מחיר ממוצע לדירה בעלת שני חדרי שינה בשכונה יוקרתית
          בלרנקה נע בין 200 ל350 אלף יורו.
        </p>
        <p className="font-bold pt-10">סוגי השקעה בנדל"ן</p>
        <p>
          קיים ביקוש רב לנדל"ן בקפריסין בקרב משקיעים זרים, החפצים בהכנסה קבועה.
          ניתן לזהות שלוש מגמות עיקריות של השקעה בנכסי הנדל"ן בקפריסין: שוק
          נדל"ן שניוני, בניה חדשה ונדל"ן מסחרי.
        </p>
        <p className="font-bold pt-10">נדל"ן שניוני בקפריסין. </p>
        <p>
          מצב המבנים שנבנו לפני שנת 2005, המוצעים בשוק הנדל"ן השניוני גרוע
          בהשוואה למבנים חדשים שנבנו אחרי 2010. ראשית כל, יעילות צריכת האנרגיה
          ועמידות בפני מפגעים סיסמולוגים במבנים הישנים נמוכה יותר. בניינים שנבנו
          בשנת 2016 או אחריה הנם בעלי יתרון מובהק מבחינות אלו. תקני הבניה
          האירופאים הוחלו על קפריסין לאחר הצטרפותה לאיחוד האירופי, בשנת 2004.
          החלת התקנים הייתה הדרגתית, אי לכך גם בשנת 2004 הושלמו פרויקטים על פי
          התקנים הישנים. בין היתרונות של רכישת הנדל"ן בשוק השניוני ניתן למנות את
          האפשרות לערוך בדיקת מסמכים של המבנה ולהעריך את איכות הבניה בפרויקט
          המוכן. יתרה מכך, מרבית הנדל"ן בשוק השניוני מוצע באזורים בעלי תשתית
          מפותחת וקהילה מגובשת.
        </p>
        <p className="font-bold">מבנים חדשים בקפריסין</p>
        <p>
          נכסים חדשים בקפריסין זוכים לביקוש גבוה, בייחוד בקרב משקיעים זרים. רוב
          העסקאות מול אזרחים זרים התרחשו בלימסול ופאפוס (34% כל אחת), אחריהם
          מגיעה לרנקה (20%). מרכיב הנדל"ן היציב ביותר בקפריסין בשנים האחרונות
          הנו מגזר המגורים, שם טווח המחירים הנו בין 100 ל300 יורו. זוהי תוצאה של
          ביקוש גבוה לדיור חדש ופעילות השקעה מתרחבת במגזר הפרטי.
          <br />
          <br /> יתרונות רכישת נכס חדש בקפריסין
        </p>

        <ul className="list-disc space-y-2 pt-5 pr-5" dir="rtl">
          <li>
            המסלול האופטימלי להשקעה בנדל"ן. במהלך ההבניה מחיר הנכס שנרכש יצמח
            בהתאם למחירי השוק.
          </li>
          <li>
            הודות לטכנולוגיות בניה חדשות והעדפה למבנים בעלי מס' קומות נמוך תקופת
            הבניה היא קצרה יחסית. גם אם הנכס נמצא בשלב עבודות העפר, המשקיע יכול
            לקבל מעמד של תושב קבע בעקבות השקעתו בנדל"ן בקפריסין.
          </li>
          <li>
            חלוקה לתשלומים ותנאי תשלום גמישים. היזמים מציעים תנאים שונים להזמנה
            ורכישת הנדל"ן, מה שמאפשר למקסם את כדאיות ההשקעה.
          </li>
        </ul>
        <p className="pt-5">חסרונות רכישת נכס חדש בקפריסין</p>
        <ul className="list-disc space-y-5 pt-5 pr-5" dir="rtl">
          <li>
            הנכסים החדשים עולים לרוב יותר מאשר נכסים מקבילים בשוק השניוני,
            במיוחד אם הם ממוקמים באזור תיירותי או בקרבת הים, שם נותרו חלקות
            פנויות מועטות, שעלותן עבור היזם גבוהה ממה שהייתה לפני 20-30 שנים.
            מאידך, רמת הטכנולוגיה ואיכות הבניה גבוהה לאין שיעור.
          </li>
          <li>
            כאשר המשקיע רוכש את נכס הנדל"ן הראשון, המע"מ הנו 5%, אך המע"מ עבור
            כל השקעה נוספת מגיע ל19%.
          </li>
        </ul>
        <p className="font-bold pt-10">נדל"ן מסחרי בקפריסין</p>
        <p>
          החלק היחסי של עסקאות נדל"ן מסחרי בשנה השוטפת הנו 4%.
          <br /> קפריסין מתאפיינת בכלכלה יציבה, שיעורי מס חברות נמוכים ותנאים
          משפטיים נוחים עבור משקיעים זרים. רפובליקת קפריסין חברה באיחוד האירופי,
          מה שמאפשר גישה לשוק המאוחד ותשלום ביורו.
          <br /> קיים ביקוש גבוה לנכסים מסחריים בקפריסין, במיוחד במגזרים כגון
          תיירות, מלונאות ומסחר קמעונאי. בכל שנה מושך האי מיליוני מבקרים,
          המעוניינים בדיור, בילוי, קניות ומסעדות. כאן נמצאים חברות וארגונים
          בינלאומיים רבים, הזקוקים למשרדים מודרניים ומאובזרים.
        </p>
        <p className="pt-10">
          מקצוענים מוסמכים מטעמינו מסייעים בסגירת עסקאות רכישה, מכירה והשכרת
          הדיור בשטח קפריסין. אנו מאתרים את הנכס, מכינים את ההסכם ומלווים את
          העסקה מבחינה משפטית. בנוסף אנו מציעים שרות שלאחר המכירה וניהול הנכסים
          שנרכשו.
        </p>
        <ul
          className="list-decimal space-y-5 pt-5 pr-5"
          dir="rtl"
        >
          <li>
            איתור הנכס. המומחים שלנו יבחרו את הנכס המתאים ביותר לדרישותיו
            האישיות של המשקיע.
          </li>
          <li>
            נסיעת היכרות. סוכנות הנדל"ן מארגנת ביקור בן 3-5 ימים ללא תשלום,
            במהלכו תוכלו לראות את כל הנכסים המועדפים ולהגיע להחלטה הסופית.
          </li>
          <li>
            בדיקת חוקיות המסמכים המשפטיים ותיאום תנאי המכירה. המומחים שלנו
            לוקחים על עצמם את כל ההתחייבויות לבדיקת מסמכי הבעלות עבור מושא
            המכירה ויסייעו לכם להגיע לתנאי קניה הסבירים ביותר.
          </li>
          <li>
            העברת הפקדון וחתימה על ההסכם. לאחר תיאום המחיר הקונה מעביר למוכר
            פיקדון/מקדמה ומאשר בכך את רצינות כוונותיו. בשלב זה נחתם הסכם המכירה
            ונקבעים תנאי התשלום.
          </li>
          <li>
            רישום הנדל"ן. לאחר העברת מלוא התשלום עבור הנכס או מתן מקדמה, בהתאם
            לתנאי היזם, ההסכם נרשם בלשכת רשם המקרקעים וזכויות הבעלות נרשמות על
            שמו של בעליו החדשים של הנכס.
          </li>
        </ul>
        <p className="py-10">
          כל השלבי ההשקעה בנדל"ן בקפריסין ניתנים לביצוע מרחוק, ז"א אונליין.
        </p>
        <p className="font-bold">
         נציגינו בקפריסין יסייעו לרוכשים להתמודד עם מאפיינים ייחודיים ודקויות תהליך רכישת הדיור. מרגע הנחיתה בנמל התעופה ועד לעזיבתכם, יתלוו אליכם בעלי מקצוע מוסמכים מטעמינו בקפריסין. עם ליווי מקצועי, תמיכה והדרכה מושכלת תימנעו מגורמי סיכון וטעויות בבחירת הנכס וסגירת העסקה, שעלולות לגרום לבעיות משפטיות ופיננסיות בהמשך
        </p>
      </div>
    </section>
  );
};

export default Content;

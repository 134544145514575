import image from "./../../images/greece-text.jpg";

const Content = () => {
  return (
    <section className="relative py-10" dir="rtl">
      <div className="container mx-auto px-10 text-right">
        <img
          className="float-right mb-5 ml-10 w-1/2"
          src={image}
          alt="Portugal img"
        />

        <h1 className="py-10 text-2xl font-bold">
          מדוע כדאי לרכוש נדל"ן ביוון
        </h1>
        <p>
          יוון היא מדינה מדהימה: היא מגוונת, מרהיבה בכל עונה, מתאפיינת במחירים
          נוחים ושפע אפשרויות לתגליות חדשות. עם זאת ליבנו נתון לאזור חלקידיקי.
          כל אחד מחלקיו: קסנדרה, סיטוניה ואפון – בעל אופי וטבע ייחודי המקסים
          בדרכו שלו. להלן מנינו כמה סיבות לכך שרכישת נדל"ן באזור חלקידיקי, יוון
          היא כדאית ביותר:
        </p>
        <ul className="list-decimal space-y-5 pt-5 pr-5" dir="rtl">
          <li>
            עלויות נוחות של דיור באזור החוף. המחירים מתחילים ב800€ למ"ר דירת
            מגורים קרוב לים. במדינות שנגן אחרות (איטליה, ספרד, צרפת) מחיר דיור
            ברמה זו הנו גבוה ב%10-20 .
          </li>
          <li>
            עלויות נמוכות של{" "}
            <span>אחזקת הדיור ביוון</span> בהשוואה
            למדינות אחרות.
          </li>
          <li>
            עלויות נמוכות של רישום הנדל"ן על שם הבעלים{" "}
            <span>
              וכמות מצומצמת של מסמכים הדרושים לשם כך.
            </span>
          </li>
          <li>
            ביקוש גבוה לדירות להשכרה. נדל"ן ביוון יכול להניב הכנסות גבוהות. כך
            למשל בעונת שיא ניתן להשכיר{" "}
            <span>דירת</span>{" "}
            <span>נופש</span> בעלת שטח כ60 מ"ר ושני
            חדרי שינה, הממוקמת קרוב לחוף{" "}
            <span>ים בחלקידיקי</span> תמורת 80-140€
            ביממה.
          </li>
          <li>
            השקעה בטוחה. המחירים בשוק הדיור השניוני ביוון אינם נופלים ממחירי
            הדיור החדש ודיור בסביבת הים נמכר בקלות. לאחר שקניתם דירה בסכום מסוים
            תמיד תוכלו למכור אותה באותו סכום לאחר כמה שנים.
          </li>
          <li>
            אפשרות קבלת ויזה תיירותית ארוכת טווח (3-5 שנים) למדינות שנגן ע' בעל
            הנדל"ן ביוון וקרובי משפחתו. ברכישת הנדל"ן שמחירן 250000 יורו ויותר
            קיימת אפשרות לקבלת "ויזת זהב" – אישור תושבות ל5 שנים עבור בעל הנדל"ן
            ובני משפחתו.
          </li>
          <li>
            איסור בניה לגובה ביוון. להבדיל ממדינות רבות (כגון בולגריה, קרואטיה,
            איטליה, ספרד ועוד) באזור חלקידיקי שביוון אין מבנים שגובהם עולה על 3
            קומות, מה שמונע עליה בצפיפות אוכלוסין.
          </li>
          <li>רמת פשיעה נמוכה.</li>
          <li>רמה גבוהה של התפתחות תיירותית: תשתית תיירות ותחבורה מפותחת.</li>
          <li>
            קו החוף הארוך ביותר. יוון נמצאת במקום השני בעולם מבחינת כמות חופי
            רחצה המסומנים בדגל תכלת.
          </li>
        </ul>
        <h1 className="py-10 text-2xl font-bold">שלבי רכישת הנדל"ן ביוון</h1>
        <p>
          הסדרה ורישום זכויות הבעלות על הנדל"ן בשטח יוון זהו תהליך שקוף, מבוקר
          וציבורי, התואם את חוקי יוון והאיחוד האירופי, שיוון חברה בו מינואר
          1981.
          <br />
          <br /> תהליך הרכישה ורישום הנדל"ן ביוון הנו פשוט ומהיר. כיום ניתן
          להפוך לבעלים חוקי של נדל"ן יווני תוך 14 ימי עבודה.
        </p>
        <ul className="list-decimal space-y-5 pt-5 pr-5" dir="rtl">
          <li>
            <h1 className=" font-bold"> בחירת הנדל"ן </h1>
            <p>
              ניתן לראות את מתקני הנדל"ן לצד תיאור מפורט{" "}
              <span>בקטלוג</span> שבאתר שלנו או
              לשלוח <span>בקשה לאיתור נדל"ן</span>{" "}
              בהתאם להעדפותיכם.
            </p>
          </li>
          <li>
            <h1 className=" font-bold"> ביקור הנדל"ן </h1>
            <p>
              סדרת הביקורים ביחידות הדיור נמשכת לרוב 2-3 ימים כאשר היום האחרון
              מוקדש בדרך כלל לביקור חוזר בנדל"ן המועדף ומשא ומתן עם הבעלים בנוגע
              לפרטי ותנאי העסקה.
            </p>
          </li>
          <li>
            <h1 className=" font-bold"> הזמנת יחידת הדיור</h1>
            <p>
              לשם שמירת יחידת הדיור מעביר הקונה מקדמה בסך 1000-3000 יורו בהתאם
              לעלות היחידה.
            </p>
          </li>
          <li>
            <h1 className=" font-bold"> הנפקת ייפוי כוח</h1>
            <p>
              ייפוי הכוח מונפק אצל הנוטריון ומעניק לעו"ד זכות לפעול בשם הקונה.{" "}
            </p>
          </li>
          <li>
            <h1 className=" font-bold">
              {" "}
              בדיקת כשירות משפטית של הנדל"ן ע' עורך דין.
            </h1>
            <p>
              עורך הדין בודק את זכויות הבעלות של המוכר ואת הסטטוס המשפטי של
              הנדל"ן אצל רשם מקרקעין. לאחר שעורך הדין יוודא את כשירותו המשפטית
              של הנדל"ן, יכין הנוטריון את הסכם המכירה וקנייה. המוכר יכין את כל
              המסמכים הדרושים למשרד הנוטריון.
            </p>
          </li>
          <li>
            <h1 className="font-bold"> שיטות תשלום עבור הנדל"ן</h1>
            <p>
              שיטת התשלום השכיחה ביותר עבור הנדל"ן היא ציון עלות היחידה על פי
              רשם המקרקעין בחוזה המכירה. על סמך סכום זה מחושבים כל המיסים
              והתשלומים (מס העברת הנכס ועמלת הנוטריון). את הסכום הרשמי המצוין
              בחוזה יש לשלם באמצעות בנק (העברה ישירה לחשבון המוכר או העברה
              לחשבון שבבעלותכם ביוון עם מתן המחאה למוכר) במידה ועלותה הממשית של
              היחידה עולה על זו המצוינת אצל רשם המקרקעין, ההפרש ישולם במזומן
              במעמד החתימה על החוזה. אפשרות נוספת – ציון העלות הממשית של יחידת
              הנדל"ן בחוזה. פירוש הדבר הוא שכל המיסים והתשלומים יחושבו על סמך
              סכום זה.
            </p>
          </li>
          <li>
            <h1 className=" font-bold"> עריכת חוזה רכישה ומכירה</h1>
            <p>
              עורך הדין יפתח עבור הקונה חשבון ביוון. לאחר העברת הכספים מחשבון
              הקונה בארץ מגוריו לחשבונו ביוון יהיה על הקונה להגיע ליוון לשם
              חתימה על חוזה מכירה וקנייה שינוסח ויאושר ע' נוטריון. מתבצע תשלום
              מיסים ומונפקת המחאה מחשבון הקונה (אשר תועבר למוכר בעת פגישה עם
              הנוטריון), לאחר מכן נחתם ההסכם. זכויות הבעלות שנרכשו נכנסות לתוקפן
              מיד עם החתימה על החוזה. במהלך מספר ימים שלאחר מכן עורך דינו של
              הקונה רושם את החוזה אצל רשם המקרקעין ומסדיר את העברת חשבונות החשמל
              והמים על שם הקונה.
            </p>
          </li>
        </ul>
        <h1 className="py-10 font-bold text-2xl">יתרונות החוק היווני</h1>
        <ul className="list-disc space-y-5 pr-5" dir="rtl">
          <li>
            לצד הזכויות על יחידת הדיור מועברות גם הזכויות על הקרקע (חלקת קרקע או
            קרקע חקלאית).
          </li>
          <li>
            אין בקרה על מוצא הכספים הזרים, ז"א האם שולמו המיסים בארץ מוצא
            הכספים, או לא.
          </li>
          <li>
            קבלת ויזה רב-מדינית לאחר החתימה על חוזה היסוד, אפשרות לקבלת תעודת
            תושב התקפה בכל מדינות שנגן עבור הקונה ובני משפחתו.
          </li>
          <li>מיסוי נמוך על קבלת רכוש ללא תמורה או קבלת עזבון הורים.</li>
          <li>
            אפשרות לרכישת נדל"ן ע' תושבים זרים שאינם אזרחי האיחוד האירופי.
          </li>
          <li>
            מתקני הדיור הממוקמים במתחם כפופים לכללי התנהגות הרשומים אצל רשם
            המקרקעין.
          </li>
        </ul>
        <p className="pt-10">
          יש לציין כי תקנון הדיור היווני הנו בין העתיקים בעולם. הוא מעניק ביטוח
          ותוקף לכל חוזה להעברת הבעלות ובנוסף לכך מהווה מסמך ממשלתי. תקנות החוק
          האירופאי החלות באופן אוטומטי בשטח יוון מעניקות לבעלי הנדל"ן מספר
          יתרונות שרק את חלקם מנינו.
        </p>
        <h1 className="text-2xl font-bold py-10">
          נציג חברתנו ילווה את הקונה בכל שלבי הרכישה.
        </h1>
        <p className="font-bold">
          נציגינו ביוון יסייעו לרוכשים להתמודד עם מאפיינים ייחודיים ודקויות תהליך רכישת הדיור. מרגע הנחיתה בנמל התעופה ועד לעזיבתכם, יתלוו אליכם בעלי מקצוע מוסמכים מטעמינו ביוון. עם ליווי מקצועי, תמיכה והדרכה מושכלת תימנעו מגורמי סיכון וטעויות בבחירת הנכס וסגירת העסקה, שעלולות לגרום לבעיות משפטיות ופיננסיות בהמשך
        </p>
      </div>
    </section>
  );
};

export default Content;

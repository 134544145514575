import React from "react";
import real from "./../../images/real.jpeg";
import sign from "./../../images/sign.png";
import diploma from "./../../images/diploma.png";
import logo from "./../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import "./About.css";

const About = () => {
  return (
    <section className="relative py-10">
      <div id="about" className="absolute _scroll-to"></div>
      <div className="container mx-auto px-10">
        <h1 className="md:text-4xl text-3xl w-full text-center pb-10">
          מי אנחנו
        </h1>
        <div className="about__content-wrap flex md:flex-row flex-col justify-center md:items-start items-center">
          <div className="about__img-wrap md:w-1/2 w-full 2xl:px-32 xl:px-24 lg:px-10 px-0">
            <img src={diploma} alt="diploma-image" />
          </div>
          <div className="about__content md:w-1/2 w-full 2xl:px-44 xl:px-36 lg:px-20 md:px-9 px-0">
            <h4 className="text-2xl text-right text-regal-blue">רי/מקס - רשת גלובלית: מעל 115000 סוכנים ב110 מדינות בעולם ו7000 משרדים</h4>
            <div className="about__content-inner">
              <p className="text-2xl text-right text-regal-blue">
                רי/מקס מעניקה לכם גישה לבסיס ההצעות הנרחב ביותר בכל כדור הארץ. בין אם חלומכם הוא בית חם על חוף הים התיכון, דירה מסוגננת בעיר גדולה בארופה או וילה מבודדת אל מול נוף אקזוטי רי/מקס תמצא הצעה בדיוק עבורכם
              </p>
              <p className="text-2xl text-right text-regal-blue">
                <b>סוכנים מקצועיים </b> <br />
                115000 הסוכנים שלנו זה לא רק מספר. זהו צוות מקצוענים מנוסים המסוגלים להגשים את חלומותיכם. אנו מבינים את הצרכים שלכם ועובדים לשביעות רצונכם המלאה
              </p>
              <p className="text-2xl text-right text-regal-blue">
                <b>בטחון ואמינות</b> <br />
                7000 המשרדים שלנו ברחבי העולם מבטיחים כי כל עסקה תושלם על פי הסטנדרטים המחמירים ביותר של בטחון ואמינות. מבחינת רי/מקס ביטחונכם הוא בגדר עדיפות עליונה. אנו מבטיחים שקיפות ומקצוענות בכל שלבי העסקה
              </p>
              <p className="text-2xl text-right text-regal-blue">
                <b>ניסיון רב שנים</b> <br />
                רי/מקס, הפועלת יותר מ45 שנים בשוק הנדל"ן הנה חברה מובילה עולמית, הבונה עבור לקוחותיה סיפורי הצלחה
              </p>
              <p className="text-2xl text-right text-regal-blue">
                בנינו גשרים בין מדינות ותרבויות והפכנו את תהליך רכישת הנדל"ן בחו"ל לחוויה פשוטה ומרתקת.
                <br />
                גישה חדשה ופילוסופיה פורצת דרך בשוק הנדל"ן. אינה מציעה ארבע קירות וקורת גג –רי/מקס בלבד, אלא נוחות ויוקרה!
                <br />
                תתחילו את דרככם אל עולם הנדל"ן עם רי/מקס!
                <br />
                צרו עמנו קשר עכשיו ותנו לנו את ההזדמנות להגשים את חלומכם על נדל"ן בחו"ל
              </p>
            </div>
            <div className="about__content-contact flex h-max items-center justify-between">
              <img
                className="2xl:w-36 lg:w-32 md:w-28 sm:w-40 w-32"
                src={logo}
                alt="logo"
              />
              <div className="header__logo-socials about__tel-btn" >
                <a href="tel: 0586557877" className="btn btn-1 btn-sep icon-info header__logo-tel">
                  <span className="header__logo-tel-txt">0586557877 </span>
                  <FontAwesomeIcon className="header__logo-tel-icon" icon={faPhone} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;

//@ts-ignore
import video2 from "./../../images/hero2.mp4";
import {useEffect, useState} from "react";
import image from "./../../images/hero-image.webp";
import "./Update.css";
import {Link} from "react-router-dom";


import Modal from "react-modal";
import styles from "../quiz/Quiz.module.css";
import portugal from "../../images/countryButton/portugal.png"
import kipr from "../../images/countryButton/kipr.svg"
import thai from "../../images/countryButton/thailand.svg"
import montenegro from "../../images/countryButton/montenegro.svg"
import grecee from "../../images/countryButton/greece.webp"
import spain from "../../images/countryButton/spain.svg"
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    borderRadius: "35px",
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(255,255,255, 0.5)",
  },
};

const countries = [
  { value: "/greece", label: "יוון", flag: grecee },
  { value: "/montenegro", label: "מונטנגרו", flag: montenegro },
  { value: "/spain", label: "ספרד", flag: spain },
  { value: "/portugal", label: "פּוֹרטוּגָל", flag: portugal },
  { value: "/kipr", label: "קַפרִיסִין", flag: kipr },
  { value: "/tailand", label: "תאילנד", flag: thai },
  
];



const Hero = () => {
	const [width, setWidth] = useState<number>(window.innerWidth);
	const [height, setHeight] = useState<number>(window.innerHeight);
	const [showArrow, setShowArrow] = useState(false);
	const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	}
	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	const isMobile = width / height < 1.45;


	setTimeout(
		() => {
			setShowArrow(true);
		}, 15000
	);

	const [isLoaded1, setIsLoaded1] = useState(false);
	const [isLoaded2, setIsLoaded2] = useState(false);
	const [isLoaded3, setIsLoaded3] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setIsLoaded1(true);
		}, 1000);
		setTimeout(() => {
			setIsLoaded2(true);
		}, 2000);
		setTimeout(() => {
			setIsLoaded3(true);
		}, 3000);
	}, []);

	return (
		<section className="relative">
			<div className="absolute top-0" id="hero"></div>
			{(showArrow && !isMobile) && (
				<div className="nav__arrow">
					<span></span>
					<span></span>
					<span></span>
				</div>
			)}
			<div className="relative">
				{isMobile ? (
					<div
						style={{
							backgroundImage: "url(./../../images/hero-image.jpeg)",
							backgroundPosition: "center",
							backgroundSize: "cover",
							backgroundRepeat: "no-repeat",
							backgroundAttachment: "fixed",
							position: "absolute",
							left: 0,
							top: 0,
							width: "100%",
							height: "100vh",
						}}
					>
						<img
							className="absolute left-0 top-0 w-screen h-screen object-cover object-top -z-10"
							src={image}
							alt="hero-image"
						/>
						<div className="container hero__title-container relative mx-auto flex items-center h-full drop-shadow-5xl">
							<div className="hero__title-wrap absolute lg:text-8xl md:text-7xl sm:text-6xl text-5xl px-10 font-bold">
								<h1
									className={
										isLoaded1
											? "text-regal-red opacity-1 translate-x-0 duration-700 text-right"
											: "text-regal-red opacity-0 translate-x-52 text-right"
									}
								>
									איך
									<br />
									רוכשים
								</h1>
								<h2
									className={
										isLoaded1
											? "text-white opacity-1 translate-x-0 duration-700 text-right"
											: "text-white opacity-0 translate-x-52 text-right"
									}
								>
									נדל"ן
								</h2>
								<h3
									className={
										isLoaded2
											? "text-regal-blue opacity-1 translate-x-0 duration-700 text-right"
											: "text-regal-blue opacity-0 translate-x-52 text-right"
									}
								>
									בחו'ל
								</h3>
							</div>
						</div>
						<div className="containerMT country-button">
							{/* <div className="wrapperMT">
								<Link to="/kipr" className="buttonMT" role="button">
									<span>Select</span>
									<div className="iconMT">
										<img className="imgMT" src={kipr} alt="kipr" />
									</div>
								</Link>
								<Link to="/montenegro" className="buttonMT" role="button">
									<span>Select</span>
									<div className="iconMT">
										<img className="imgMT" src={montenegro} alt="montenegro" />
									</div>
								</Link>
								<Link to="/spain" className="buttonMT" role="button">
									<span>Select</span>
									<div className="iconMT">
										<img className="imgMT" src={spain} alt="spain" />
									</div>
								</Link>
								<Link to="/tailand" className="buttonMT" role="button">
									<span>Select</span>
									<div className="iconMT">
										<img className="imgMT" src={tailand} alt="tailand" />
									</div>
								</Link>
								<Link to="/greece" className="buttonMT" role="button">
									<span>Select</span>
									<div className="iconMT">
										<img
											className="imgMT"
											src={require("../../images/countryButton/greece.webp")}
											alt="greece"
										/>
									</div>
								</Link>
								<Link to="/portugal" className="buttonMT" role="button">
									<span>Select</span>
									<div className="iconMT">
										<img
											className="imgMT"
											src={require("../../images/countryButton/portugal.png")}
											alt="portugal"
										/>
									</div>
								</Link>
							</div> */}

							<button
								onClick={openModal}
								className="interlayer__btn animated-button1"
							>
								<span></span>
								<span></span>
								<span></span>
								<span></span>
								בחר מדינה
							</button>
							<Modal
								isOpen={modalIsOpen}
								onRequestClose={closeModal}
								style={customStyles}
								contentLabel="Modal"
							>
								<div className="2xl:w-[1036px] lg:w-[780px] w-screen relative mx-auto flex flex-col items-center bg-white overflow-hidden">
									<button
										onClick={closeModal}
										className="absolute z-10 right-6 top-6"
									>
										<FontAwesomeIcon
											icon={faXmark}
											className="text-2xl hover:text-regal-red"
										/>
									</button>

									<div className={styles.progressWrap}>
										<h1 className="country-button__modal-title md:text-lg sm:text-md text-sm w-5/6 flex justify-center">
											בחר מדינה
										</h1>
									</div>
									<div className="country-button__content">
										<div className="country-button__buttons">
											{
												countries.map((country:any, i:number) => (
													<Link to={country.value} key={i} className="country-button__btn btn btn-1 btn-sep icon-info header__logo-tel">
														<span className="header__logo-tel-txt">{country.label}</span>
														<img src={country.flag} alt="flag" className="country-button__flag" />
													</Link>
												))
											}
										</div>
										
									</div>
								</div>
								
							</Modal>
						</div>
					</div>
				) : (
					<video
						className="absolute left-0 top-0 w-screen h-screen object-cover -z-10"
						autoPlay={true}
						playsInline={true}
						muted={true}
						src={video2}
						loop
					></video>
				)}
			</div>

			<div className="container mx-auto w-full min-h-screen text-white px-10"></div>
		</section>
	);
};

export default Hero;

import image from "./../../images/thai-text.jpeg";

const Content = () => {
  return (
    <section className="relative py-10" dir="rtl">
      <div className="container mx-auto px-10 text-right">
        <img
          className="float-right mb-5 ml-10 w-1/2"
          src={image}
          alt="Portugal img"
        />
        <p>
          <b>
            מה היתרונות בבעלות על בית פרטי בממלכה? מדוע כדאי לרכוש דיור בארץ
            החיוכים דווקא עכשיו?
          </b>
        </p>
        <p className="py-10 font-bold">עלויות הנדל"ן</p>
        <p>
          תאילנד הנה אטרקטיבית עבור משקיעים זרים בזכות מחירי הנדל"ן הנוחים. על
          פי הערכות מומחים, השקעת הסף המינימלית מהווה 80-140 אלף דולר ארה"ב עבור
          נכסים איכותיים. כך למשל תמורת 80-140 אלף דולר ניתן לרכוש סטודיו מרוהט
          בשטח 30-35 מ"ר במתחם מגורים באזור תיירותי מבוקש עם תשתיות נפרדות או
          חדר מלון בן 30 מ"ר במתחם בשלבי הקמה.
          <br />
          <br /> מחירי הווילות הפרטיות בתאילנד מתחילים מ180 אלף דולר ארה"ב. עבור
          סכום זה ניתן לקבל נכס בעל שני חדרי שינה במתחם סגור בשלבי בניה. לעתים
          ניתן למצוא הצעות זולות פי 1.5 ואף פי 2 במקרים בהם היזם עורך מבצע
          שיווקי לצורך קידום הפרויקטים החדשים, אך בכל פרויקט יש שלוש או לכל
          היותר חמש יחידות מסוג זה.
          <br />
          <br /> המחיר הסופי יושפע מגורמים שונים ומגוונים: נוף אל הים, קרבת
          החוף, מגוון התשתיות הקיימות במתחם המגורים וכן המרחק מחנויות, בתי ספר,
          בתי קפה ועוד.
        </p>
        <p className="py-10 font-bold">יעדים מבוקשים לרכישת הנדל"ן בתאילנד</p>
        <p>
          המקום המבוקש ביותר מבחינת רכישת נדל"ן בתאילנד – פוקט. על סמך נתוני
          המומחים כאן נסגרות יותר ממחצית כלל העסקאות בהשתתפות אזרחי רוסיה. במקום
          השני מבחינת ביקוש – פאטאיה, במקום השלישי- בנגקוק.
          <br />
          <br /> פוקט היא היעד הפופולרי ביותר להשקעה נכון להיום.
          <br />
          <br /> זוהי עיר נופש יוקרתית, בעלת תשתית מפותחת וזרם תיירים יציב לאורך
          כל השנה, מה שהופך את העיר ליעד אטרקטיבי לא רק לצורך רכישת דיור לשימוש
          אישי אלא גם להשקעה בנכס לצורך השכרה. עם זאת, הנדל"ן בפוקט יקר יחסית.
          כך למשל מחיר דירה בת 30 מ"ר בבית משותף מתחיל מ120 אלף דולר ארה"ב ומחיר
          וילה פרטית עם שני חדרי שינה באזור מאובטח מתחיל ב280 אלף דולר.
          <br />
          <br /> קיימים גם יעדים מבוקשים עם עלויות מתונות יותר. בפאטאיה למשל
          ניתן בקלות למצוא דירה מודרנית בעלת שטח כ50 מ"ר עבור 100 אלף דולר.
          <br />
        </p>
        <p className="font-bold py-10">יתרונות בבעלות על נדל"ן בתאילנד </p>
        <p>
          ויזה ארוכת טווח
          <br />
          <br /> אלו שמשקיעים בכלכלת המדינה זכאים לוויזה ארוכת טווח מאחד הסוגים.
          זה מאפשר לשהות בתאילנד לאורך תקופה ארוכה ללא צורך לעזוב אותה לשם הערכת
          האשרה.
          <br />
          <br /> חשבון בנק זר
          <br />
          <br /> במהלך רכישת הנדל"ן קל יותר לפתוח חשבון בנק אישי ולקבל כרטיס בנק
          תאילנדי. עם אשרת תייר התהליך מורכב יותר וזמינות השירותים הבנקאיים הנה
          מוגבלת. כרטיס החיוב התאילנדי ניתן לשימוש בכל העולם, לרבות תשלום
          בחנויות מקוונות.
          <br />
          <br /> הכנסה פאסיבית
          <br />
          <br /> תוכלו להשכיר את הנכס שבבעלותכם בתאילנד ולקבל הכנסה פאסיבית נאה.
          רווחיות ההשקעה בנדל"ן הנה כ8% בשנה, שזה נתון מכובד ביותר.
          <br />
          <br /> תוכלו להשכיר את דירתכם באופן עצמאי. לבחירתכם תוכלו למצוא דיירים
          לטווח ארוך או להשכיר את הדירה רק כשאינכם משתמשים בה.
          <br />
          <br /> בנוסף לכך, חברות הניהול שלנו בבתים המשותפים וכן היזמים לעתים
          קרובות מציעים לחתום על הסכם השכרה עם הכנסה מובטחת. במקרה זה תהיה
          הרווחיות נמוכה מעט יותר, אך ההכנסה תהיה מובטחת גם אם הנכס יהיה פנוי
          משוכרים במשך זמן מה. במקרה כזה לא תצטרכו לדאוג לתשלום חשבונות ומיסים,
          המנהל יבצע זאת עבורכם.
          <br />
          <br /> דירת נופש זמינה בכל עת
          <br />
          <br /> לא תצטרכו יותר להתאמץ ולחפש דירה בזמן שהותכם בתאילנד! בכל עת
          אתם או חבריכם ובני משפחתכם תוכלו להגיע לתאילנד וליהנות מחופשה עם תחושה
          של בית.
          <br />
          <br /> בעלות על נכס בתאילנד אינה מחייבת אתכם לבלות את חופשתכם במדינה
          זו בלבד! תאילנד היא נקודת יציאה נוחה ביותר לטיול בכל רחבי אסיה, כי נמל
          התעופה בבנגקוק הנו מרכז התחבורה הגדול ביבשת זו.
          <br />
          <br /> סיוע ברכישת הנדל"ן
          <br />
          <br /> באתר שלנו תוכלו לעיין בהצעות הזמינות בשוק הנדל"ן בתאילנד.
          נציגנו בתאילנד יעזרו לכם לבחור את הנכס המושלם בכל עיר במדינה זו.
          נציגנו המקצועיים ישמחו לענות לכל שאלה ולהעניק לכם ייעוץ וליווי בכל
          שלבי העסקה.
        </p>
        <p className="font-bold pt-10">
          נציגינו בתאילנד יסייעו לרוכשים להתמודד עם מאפיינים ייחודיים ודקויות תהליך רכישת הדיור. מרגע הנחיתה בנמל התעופה ועד לעזיבתכם, יתלוו אליכם בעלי מקצוע מוסמכים מטעמינו בתאילנד. עם ליווי מקצועי, תמיכה והדרכה מושכלת תימנעו מגורמי סיכון וטעויות בבחירת הנכס וסגירת העסקה, שעלולות לגרום לבעיות משפטיות ופיננסיות בהמשך
        </p>
      </div>
    </section>
  );
};

export default Content;

import { useDispatch } from "react-redux";
import { setRoomRange, setStep } from "../../redux/quizReducer";
import "./../../index.css";
import styles from "./Quiz.module.css";
import henryThink from "./../../images/henry-think.png";


import { Formik, Form, Field } from "formik";

function validateNumber(value: string) {
  let error;
  if (!value) {
    error =
      "כמה חדרים?";
  }
  return error;
}

const ThirdStep = () => {
  const dispatch = useDispatch();


  return (
    <div className="relative w-full h-full">
      <Formik
        initialValues={{
          minRooms: "",
          maxRooms:  "",
        }}
        onSubmit={(values: any) => {
          dispatch<any>(setRoomRange([values.minRooms, values.maxRooms]));
          dispatch<any>(setStep(5));
        }}
      >
      {({ errors, touched, isValidating }) => (
        <Form>
          <div className="flex flex-col">
            <h1 className="text-center text-lg mb-5">כמות החדרים</h1>
            <div>
              <div className={styles.priceWrap}>
                <div className={styles.formPriceWrapper}>
                  <p className={`${styles.formPriceTitle} top-text`}>עד</p>
                  <Field
                    className={`${styles.formPrice} text-right`}
                    name="minRooms"
                    validate={validateNumber}
                  />
                </div>
                <div className={styles.formPriceWrapper}>
                  <p className={`${styles.formPriceTitle} top-text`}>מ</p>
                  <Field
                    className={`${styles.formPrice} text-right`}
                    name="maxRooms"
                    validate={validateNumber}
                  />
                </div>
              </div>
            </div>
            <div className="text-regal-red md:text-lg text-sm text-center err-text">
              {(errors.maxRooms && touched.maxRooms && (
                //@ts-ignore
                <div>{errors.maxRooms}</div>
              )) ||
                (errors.minRooms && touched.minRooms && (
                //@ts-ignore
                  <div>{errors.minRooms}</div>
                ))}
            </div>
            <div className="flex step5-img">
              <div className="flex flex-col items-center">
                <img
                  className="md:w-60 md:h-60 w-36 h-36 -mt-8"
                  src={henryThink}
                  alt="henry-image4"
                />
                <p className="text-center md:text-lg text-md">
                  ...וואו, כמה חדרים כדאי לבחור
                </p>
              </div>
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <button className={styles.button} type="submit">
              המשך
            </button>

            <button
              className={styles.backButton}
              onClick={() => {
                dispatch<any>(setStep(3));
              }}
            >
              חזור
            </button>
          </div>
        </Form>
      )}
      </Formik>
    </div>
  );
};

export default ThirdStep;

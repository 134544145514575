import image from "./../../images/montenegro-text.jpg";

const Content = () => {
  return (
    <section className="relative py-10" dir="rtl">
      <div className="container mx-auto px-10 text-right">
        <img className="float-right mb-5 ml-10 w-1/2" src={image} />
        <h1 className=" font-bold">מה מהווה שוק הדיור במונטנגרו</h1>
        <p>
          שוק הדיור במונטנגרו מכוון לא רק לרוכשים המקומיים אלא גם למשקיעים זרים.
          בערי החוף נבנים מתחמי דיור רבים ברמות יוקרה שונות. בנוסף קיים שוק
          שניוני נרחב המציע יחידות דיור רבות, רובן ממוקמות בערי ויישובי נופש:
          בודווה, בצ'יצ'י, רפאילוביצ'י, אולצין, בר ופטרובץ.
          <br />
          <br /> חלק ניכר של שוק הדיור במונטנגרו מתמקד בבתי נופש, בהם ניתן לבלות
          בקיץ וליהנות מהשמש והים. עם זאת כדאי לזכור כי דיור מסוג זה אינו מתאים
          למגורים לאורך כל השנה כי בתום עונת התיירות החיים באזורים רבים של
          מונטנגרו עומדים מלכת.
        </p>
        <h1 className="pt-10 font-bold">כמה עולה דירה במונטנגרו</h1>
        <p>
          שוק הדיור מתמקד בעיקר בחמישה ערים: בודווה, הרצג-נובי, בר, טיווט
          ופראסט.
          <br /> על פי הנתונים האחרונים המחיר הממוצע למ"ר בדירות ובתים נכון
          לתחילת אוגוסט 2023 הנו:
          <br /> בשוק הבנייה החדשה בבודווה: 4,1 אלף יורו, בשוק השניוני – 2,9 אלף
          יורו, בבית פרטי – 2,4 אלף יורו.
          <br /> בבניינים החדשים של הרצג-נובי – 3,1 אלף יורו, בשוק השניוני – 4,3
          אלף יורו, בבית פרטי –2,5 אלף יורו.
          <br /> בבניינים החדשים של בר – 2,5 אלף יורו, בשוק השניוני –2,2 אלף
          יורו, בבית פרטי –1,4 אלף יורו.
          <br /> בטיווט – 3,4 אלף יורו בבניין חדש, בשוק הדיור הקיים – 4,1 אלף
          יורו, בבית פרטי – 3,4 אלף יורו.
          <br /> בפטרובץ' – 1,6 אלף יורו בשוק הראשוני ובשוק השנייוני - 2 אלף
          יורו, בבית פרטי – 3,4 אלף יורו.
        </p>
        <h1 className="pt-10 font-bold">איך קונים דירה במונטנגרו</h1>
        <p>להלן השלבים העיקריים:</p>
        <ul className="list-disc space-y-2 pt-5 pr-5" dir="rtl">
          <li>העברת פקדון שלרוב לא עולה על 5% מסך העסקה;</li>
          <li>
            חתימה על חוזה מקדים של קניה ומכירה (מתבצע באמצעות נוטריון בלבד);
          </li>
          <li>חתימה על חוזה קניה ומכירה סופי (מתבצע באמצעות נוטריון);</li>
          <li>רישום זכויות הבעלות בלשכת רשם המקרקעין של מונטנגרו;</li>
        </ul>
        <p className="pt-10">
          המומחים מדגישים כי חוזה מקדים וסופי של קניה ומכירה מנוסח בשפה המקומית
          בלבד. בנוסף לנוטריון במעמד החתימה חייב להיות נוכח גם מתרגם מקומי בעל
          רישיון. הוא יתרגם את כל פרקי החוזה ויסייע בתקשורת עם הנוטריון ובעלי
          הנדל"ן (במקרה של רכישת דירה בשוק השניוני) או נציג קבלן הבניה (במקרה של
          רכישת דיור חדש).
        </p>
        <p className="font-bold pt-10">מה צריך לכלול חוזה קניה ומכירה?</p>
        <p>
          מידע אודות הצדדים החותמים על ההסכם, כולל שם פרטי, שם משפחה ושם אב (אם ידוע), פרטי זיהוי. הצהרת הקונה על כוונותיו לרכוש נדל"ן, אישור תשלום אגרת רישום, מידע אודות יחידת הנדל"ן, אישור תשלום אגרת רישום, מידע אודות יחידת הנדל"ן, תיאורה המפורט ותמצית רשם ממשלתי אחיד, הכוללת מידע אודות הנדל"ן, מחיר היחידה, תוכניות ומועדי התשלום, מחויבויות המוכר והקונה, סעיף אודות הגנה מדרישות צד ג' (עבור השוק השניוני). חתימות המוכר, הקונה, הנוטריון והמתרגם.
        </p>
        <p className="font-bold pt-10">אילו מיסים משלם בעל נדל"ן במונטנגרו</p>
        <p>
          על הקונה לשלם את מס העברת נדל"ן –3% מעלות היחידה. המס חל על העסקה אם מוכר הדירה החדשה או הקיימת הנו אדם פרטי. מס בעלות על נדל"ן השנתי הנו בין 0,25% ל1% ומחושב בהתאם לעלותו הבסיסית של נדל"ן. מס תיירות על נכסים הממוקמים באזור תיירות (פודגוריצה וכל קו החוף). עבור בעלי הנדל"ן המס הזה מחושב על סמך זמן שהותו הממוצע של תייר בארץ – 45 ימים. נוסחת
        </p>
        <p className="font-bold pt-10">האם ניתן לקנות דירה במונטנגרו מרחוק</p>
        <p className="pb-10">
          לשם השלמת העסקה על הקונה לבקר במדינה או לשלוח נציג עם ייפוי כוח
          נוטריוני, כולל תרגום מאושר לשפה המקומית.
        </p>
        <p className="font-bold">
          נציגינו במונטנגרו יסייעו לרוכשים להתמודד עם מאפיינים ייחודיים ודקויות תהליך רכישת הדיור. מרגע הנחיתה בנמל התעופה ועד לעזיבתכם, יתלוו אליכם בעלי מקצוע מוסמכים מטעמינו במונטנגרו. עם ליווי מקצועי, תמיכה והדרכה מושכלת תימנעו מגורמי סיכון וטעויות בבחירת הנכס וסגירת העסקה, שעלולות לגרום לבעיות משפטיות ופיננסיות בהמשך
        </p>
      </div>
    </section>
  );
};

export default Content;
